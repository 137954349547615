@import "assets/styles/common/mixins.scss";
@import "assets/styles/common/colors.scss";

.currency-row {
  border-radius: 50%;
  height: 24px !important;
  width: 24px !important;
  background-position: center;
  margin-right: 8px;
}

.selecte-label {
  @include text(400, 14px, 16px, $primary-color);
  letter-spacing: 0.4px;
}
