$primary-color: #175fff;
$primary-color-trans: #175fff33;
$pure-white: #ffffff;
$light-100: #f6f7f9;

$dark-020: #0000001a;
$dark-050: #a7a7a881;
$dark-100: #3a434b;
$dark-150: #353535;
$dark-180: #111111;
$dark-200: #0d0f11;
$dark-250: #24292e;
$pure-black: #000;

$gray-040: #f9f9f9;
$gray-050: #f5f5f5;
$gray-060: #e1e1e1;
$gray-100: #d7dce0;
$gray-150: #c4c4c4;
$gray-200: #bdbdbd;
$gray-300: #aaaaaa;
$gray-400: #838383;
$gray-500: #777777;

$blue-400: #5b8ef0;
$blue-900: rgba(23, 95, 255, 0.2);

$spb-600-color: #1354d3; //sparta blue
$spg-600-color: #27be63; //sparta green
$spo-500-color: #f08829; // sparta orange

//dark neutrals
$spn-900-color: #1d2730; //sparta dark neutral 1
$spn-800-color: #304050; //sparta dark neutral 2
$spn-700-color: #435a70; //sparta dark neutral 3
$spn-750-color: #505d67;

//light neutrals
$spn-200-color: #d7e0e9; //light neutral 1
$spn-100-color: #f1f4f8; //light neutral 2
$spn-000-color: #ffffff; //white
$spn-050-color: #f4f5f6;

//secondary colors
$spr-500-color: #e43535; //sparta red
$spy-500-color: #ffc31a; //sparta yellow
$spp-500-color: #5e4bce; //sparta purple
$spt-600-color: #22b9c3; //sparta teal

//Extended Palette for sparta Blue
$spb-050-color: #f8faff;
$spb-100-color: #e8effd;
$spb-200-color: #b9cff9;
$spb-300-color: #8aaef4;
$spb-400-color: #5b8ef0;
$spb-500-color: #2c6eec;
$spb-600-color: #1354d3;
$spb-700-color: #0f42a4;
$spb-800-color: #0e347e;
$spb-900-color: #0d0f11;

//Extended Palette for sparta green
$spg-100-color: #eafbf1;
$spg-200-color: #c0f2d4;
$spg-300-color: #95e9b7;
$spg-400-color: #6be19a;
$spg-500-color: #41d87d;
$spg-700-color: #1e944d;
$spg-800-color: #166a37;
$spg-900-color: #28a745;

//Extended Palette for sparta orange
$spo-100-color: #fdf2e7;
$spo-200-color: #fad7b8;
$spo-300-color: #f7bd88;
$spo-400-color: #f3a358;
$spo-600-color: #d66f0f;
$spo-700-color: #a7560c;
$spo-800-color: #773e08;

//Extended Palette for sparta red
$spr-100-color: #fce9e9;
$spr-200-color: #f6bcbc;
$spr-300-color: #f08f8f;
$spr-400-color: #ea6262;
$spr-600-color: #ca1b1b;
$spr-700-color: #901515;
$spr-800-color: #700f0f;

//Extended Palette for sparta yellow
$spy-100-color: #fff8e5;
$spy-200-color: #ffebb3;
$spy-300-color: #ffde80;
$spy-400-color: #ffd04d;
$spy-600-color: #e6a900;
$spy-700-color: #b38400;
$spy-800-color: #805e00;

//Extended Palette for sparta purple
$spp-100-color: #edebfa;
$spp-200-color: #c9c3ef;
$spp-300-color: #a59be4;
$spp-400-color: #8273d9;
$spp-600-color: #4431b4;
$spp-700-color: #35268c;
$spp-800-color: #261b64;

//Extended Palette for sparta teal
$spt-100-color: #e9fafb;
$spt-200-color: #bef0f4;
$spt-300-color: #92e6ec;
$spt-400-color: #67dde4;
$spt-500-color: #3cd3dd;
$spt-700-color: #1b9098;
$spt-800-color: #13676d;

//Extended Palette for sparta neutral

$spn-050-color: #f4f5f6;
$spn-300-color: #afc0cf;
$spn-400-color: #81909d;
$spn-500-color: #677684;
$spn-600-color: #567490;
$spn-700-color: #435a70;
