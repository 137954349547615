@import "assets/styles/common/colors.scss";
@import "assets/styles/common/mixins.scss";

.sc-doc-item {
  padding: 16px;

  background: $pure-white;
  border: 1px solid $gray-100;
  border-radius: 8px;
  cursor: pointer;
  .doc-title {
    @include text(500, 16px, 16px, $dark-180);
    letter-spacing: -0.05px;
  }

  .doc-desc,
  .doc-number {
    @include text(500, 12px, 16px, $gray-500);
    letter-spacing: 0.4px;
  }
}
