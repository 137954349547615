@import "assets/styles/common/colors.scss";
@import "assets/styles/common/mixins.scss";

.payout-modal {
  &__footer {
    margin-block: 20px;
  }

  .pointer {
    cursor: pointer;
    color: #1354d3;
  }

  .note {
    min-height: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background: $spy-100-color;
    border: 1px solid $spo-500-color;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.1);
    border-radius: 5px;

    i {
      margin: 0 17.33px;
      color: $spo-500-color;
    }

    &-content {
      @include text(400, 12px, 16px, $spo-500-color);
      letter-spacing: 0.002em;

      .mail-link {
        color: inherit;
        text-decoration: underline !important;
        font-weight: 500;

        &:hover {
          text-decoration: none !important;
        }
      }
    }
  }
}
