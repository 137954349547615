.customer-info {
  .border-r {
    border-right: 1px solid #d7dce0;
  }

  .profile {
    height: 100px;
    width: 100px;
    border-radius: 50%;
    background-image: url(https://leadership.ng/wp-content/uploads/2023/03/davido.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-inline: auto;
  }
}
