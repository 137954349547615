@import "assets/styles/common/colors.scss";
@import "assets/styles/common/mixins.scss";

.card-wrapper {
  transition: all 0.2s;
  cursor: pointer;
  &:hover {
    background-color: #f4f5f6;
  }

  .arrow {
    color: #0c5449;
    background-color: #f6f3ed;
    margin: 1em 0;

    &::after {
      display: inline-block;
      -webkit-transition: transform 0.3s ease-out;
      -moz-transition: transform 0.3s ease-out;
      -ms-transition: transform 0.3s ease-out;
      -o-transition: transform 0.3s ease-out;
      transition: transform 0.3s ease-out;
    }

    &:hover {
      background-color: #f6f3ed;

      &::after {
        -webkit-transform: translateX(4px);
        -moz-transform: translateX(4px);
        -ms-transform: translateX(4px);
        -o-transform: translateX(4px);
        transform: translateX(4px);
      }
    }
  }
}

.bank-detail {
  // border-bottom: 1px solid $spr-100-color;

  .icon-container {
    // background-color: $blue-900;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;

    &.number-icon {
      font-weight: 600;
      font-size: 9px;
    }
  }

  .detail-label {
    font-weight: 400;
    color: $dark-100;
  }

  .detail-label,
  .detail-value {
    font-size: 14px;
    line-height: 16px;
    /* identical to box height, or 114% */
    letter-spacing: 0.4px;
  }

  .detail-value {
    font-weight: 500;
    color: $spb-900-color;
    i.ri-file-copy-line {
      cursor: pointer;
      // color: $primary-color !important;
      &:hover {
        opacity: 0.85;
      }
    }
  }
}
