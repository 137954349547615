@import "~vgg-ui-react/dist/styles/partials";

.onboard-wrapper {
  .f-11 {
    flex: 11 !important;
  }
  .msg-wrapper {
    flex: 1; font-size: 1.5rem;
  }

  .learn-more-btn {
    background: #fff;
              border: 1px solid #D7DCE0;
              font-weight: 500;
              font-size: 14px;
              line-height: 16px;
              letter-spacing: 0.4px;
              color: #505D67;
  }

  .onboard-btn {
    font-weight: 500;
              font-size: 14px;
              line-height: 16px;
              letter-spacing: 0.4px;
  }
  .head-section {
    &__icon {
      background: #e8f0ff;
      border-radius: 6px;
      color: #175fff;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      padding: 10px;
      cursor: pointer;
      transition: opacity 0.3s;

      i {
        font-size: 25px;
      }

      &:hover {
        opacity: 0.8;
      }
    }

    &__title {
      .pg-title {
        font-weight: $sp-fontweight--600;
      }
      .pg-subtitle {
        font-weight: $sp-fontweight--600;
      }
    }
  }

  .card-section {
    background: #f8faff;
    border: 1px solid #d7dce0;
    border-radius: 4px;

    .content-paragraph {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: -0.048px;
      color: #3a434b;
    }

    .footnote {
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      /* or 114% */

      letter-spacing: 0.4px;

      /* SPN/SPN 900 */

      color: #0d0f11;
    }
  }
}
