@import "assets/styles/common/colors.scss";
@import "assets/styles/common/mixins.scss";

.sc-breadcrumb {
  background: transparent;
  .breadcrumb-item {
    @include text(400, 14px, 24px, $spn-400-color);
    letter-spacing: 0.4px;
    a {
      @include text(400, 14px, 24px, $spn-700-color);
    }
  }
}
