@import "assets/styles/common/colors.scss";
@import "assets/styles/common/mixins.scss";

.sc-settings {
  .wrap {
    background: $pure-white;
    border: 1px solid $gray-100;
    border-radius: 8px;
    border-radius: 7px;

    overflow: hidden;
    padding: 24px;
    height: 100%;

    &.active {
      border: 2px solid $primary-color;
    }

    &.popular {
      border: 2px solid $primary-color;
      background: $spb-100-color;
    }

    .chip-popular {
      padding: 4px 8px;
      @include text(500, 10px, 16px, $pure-white);
      position: absolute;
      top: -13px;
      right: 28px;
      background: $primary-color;
      border-radius: 8px;
      display: flex;
      align-items: center;
      letter-spacing: 0.2px;
      text-transform: uppercase;
    }
  }

  .variant-subtitle {
    @include text(500, 14px, 16px, $spb-900-color);
    letter-spacing: 0.4px;
  }

  .variant-subtitle2-primary {
    @include text(600, 14px, 16px, $primary-color);
    letter-spacing: 0.002em;
  }

  .variant-body1 {
    @include text(500, 16px, 16px, $spb-900-color);
    letter-spacing: -0.05px;
  }

  .variant-title {
    @include text(500, 24px, 24px, $dark-180);
    letter-spacing: 0.2px;
  }

  .variant-caption {
    @include text(400, 12px, 16px, $gray-500);
    letter-spacing: 0.4px;
  }

  .variant-caption1 {
    @include text(400, 14px, 24px, $gray-500);
    letter-spacing: 0.4px;
  }

  .variant-btn-text {
    @include text(600, 14px, 16px, $primary-color);
    letter-spacing: 0.002em;
    text-decoration: none;

    &:hover {
      opacity: 0.75;
    }
  }

  .progress {
    height: 6px !important;

    .progress-bar {
      background-color: $primary-color !important;
    }
  }
}
