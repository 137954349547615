@import "assets/styles/common/colors.scss";
@import "assets/styles/common/mixins.scss";

.sc-personal-info {
  .person-name {
    @include text(600, 16px, 16px, $pure-black);
  }
}

.person-row-item {
  background: $spn-050-color;
  border-radius: 8px;
  .sc-person {
    &-label {
      @include text(400, 14px, 16px, $dark-100);
      letter-spacing: 0.4px;
    }

    &-value {
      @include text(400, 16px, 16px, $spb-900-color);
      letter-spacing: -0.05px;
    }
  }
}
