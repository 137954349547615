@import "assets/styles/bootstrap-override.scss";
@import "~vgg-ui-react/dist/styles/index.scss";
@import "remixicon/fonts/remixicon.css";
@import "assets/styles/common/colors.scss";
@import "assets/styles/common/mixins.scss";

* {
  scrollbar-width: thin;
  scrollbar-color: $spb-600-color $spn-200-color;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* width */
*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px $spn-200-color;
  border-radius: 5px;
}

/* Handle */
*::-webkit-scrollbar-thumb {
  background: $spb-600-color;
  border-radius: 5px;
}

/* Handle on hover */
*::-webkit-scrollbar-thumb:hover {
  background: $spb-800-color;
}

html {
  font-size: 16px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Inter", "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.bg-gray {
  background-color: $gray-060;
}

.pagination-container {
  padding: 32px 0 !important;
  .pagination-item {
    font-size: 14px !important;

    &.selected {
      color: $primary-color;
      background-color: $primary-color-trans;
    }
  }
}

.pagination-nav {
  font-size: 14px !important;
}

.shimmer-line {
  width: 100%;
  height: 8px;
  align-self: center;
  border-radius: 8px;
}

@mixin fade-in {
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.2s;
}

@mixin fade-out {
  opacity: 0;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.2s;
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fade_in {
  @include fade-in;
}

.fade_out {
  @include fade-out;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: $pure-white !important;
}
.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-accent-bg: $pure-white !important;
}

.table-striped tbody tr:nth-of-type(even) {
  background-color: $spn-050-color !important;
}

.table td {
  border-top: none;
}

.table > :not(caption) > * > * {
  border-bottom-width: 0;
}

.table thead {
  background-color: $spb-100-color;
}

.tab-content {
  padding: 0px;
}

.bb {
  &-100 {
    border-bottom: 1px solid $gray-100;
  }

  &-200 {
    border-bottom: 1px solid $gray-400;
  }
}

.swal-icon--info {
  border-color: $spo-500-color !important;
}
.swal-icon--info:after,
.swal-icon--info:before {
  background-color: $spo-500-color !important;
}
.swal-button {
  background-color: $primary-color !important;
}
.swal-button:hover {
  opacity: 0.75 !important;
}

.table-wrapper {
  border: 1px solid $gray-100;
  box-shadow: 0px 0px 3px $dark-020;
  border-radius: 8px;

  .border-bottom {
    border-bottom: 1px solid $gray-100 !important;

    .page-title {
      @include text(600, 1.125rem, 24px, $pure-black);
      letter-spacing: 0.25px;
    }
  }

  .pagination-container {
    padding: 0 !important;

    .pagination-container {
      border-bottom: 0em !important;
    }
  }
}

.no-bb {
  border-bottom: none !important;
}

.no-bt {
  border-top: none !important;
}

.variant-btn-text-primary {
  @include text(500, 14px, 16px, $primary-color);
  letter-spacing: 0.002em;
  text-decoration: none;

  &:hover {
    opacity: 0.75;
  }
}

.search-container {
  width: 300px;
  position: relative;
  i {
    position: absolute;
    top: 32%;
    right: 24px;
    color: $dark-250;
  }
}

.pg-action-btn {
  width: 150px;
  height: 40px;
  border: 0;
}

.hv-60 {
  height: 60vh;
}
