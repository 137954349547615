.drawer {
  &.fadeInRight {
    -webkit-animation-name: fadeInRight;
    animation-name: fadeInRight;
  }

  @keyframes fadeInRight {
    from {
      opacity: 0;
      -webkit-transform: translate3d(100%, 0, 0);
      transform: translate3d(100%, 0, 0);
    }

    to {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }

  @-webkit-keyframes fadeInRight {
    from {
      opacity: 0;
      -webkit-transform: translate3d(100%, 0, 0);
      transform: translate3d(100%, 0, 0);
    }

    to {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }

  &.modal-dialog {
    margin: 0;
    height: 100%;
  }

  &.w-500 {
    max-width: 500px;
  }

  &.animated {
    -webkit-animation-duration: 0.5s;
    animation-duration: 0.5s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }

  .modal-header {
    .border-bottom {
      border-bottom: 1px solid #f5f6f7 !important;
    }
    border-radius: 0;
    align-items: center;

    .modal-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin-left: 10px;
    }
  }

  .modal-content {
    border-radius: 0;
    border: 0;
    -webkit-box-shadow: -12px 0 38px -14px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: -12px 0 38px -14px rgba(0, 0, 0, 0.25);
    box-shadow: -12px 0 38px -14px rgba(0, 0, 0, 0.25);
    background-clip: padding-box;
    min-height: 100vh;
  }

  .modal-backdrop {
    background-color: transparent;
  }

  .modal-button-container {
    margin: 0 auto;
    width: 90%;
    max-width: 500px;
  }

  .btn {
    border-radius: 0;
  }
}
