@import "assets/styles/common/colors.scss";
@import "assets/styles/common/mixins.scss";
.change-plan-modal-wrapper {
  .wrap-icon {
    width: 100px;
    height: 100px;
    background: $spb-100-color;
    border-radius: 50%;
  }

  .sc-change {
    &-title {
      @include text(600, 20px, 16px, $pure-black);
    }

    &-description {
      @include text(400, 16px, 24px, $spb-900-color);
      letter-spacing: -0.05px;
    }
  }
  .cancel-btn {
    background-color: $spb-100-color;
    color: $spb-900-color;

    &:hover {
      opacity: 0.75;
    }
  }
}
