@import "assets/styles/common/colors.scss";
@import "assets/styles/common/mixins.scss";

.person-card {
  cursor: pointer;
  .card-title {
    @include text(500, 16px, 16px, $dark-180);
    letter-spacing: -0.05px;
  }

  .card-subtitle {
    @include text(500, 12px, 16px, $gray-500);
    letter-spacing: 0.4px;
  }

  .card-text {
    @include text(500, 12px, 16px, $dark-180);
    letter-spacing: 0.4px;
  }
}
