@import "assets/styles/common/colors.scss";
@import "assets/styles/common/mixins.scss";

.view-transaction {
  .border-bottom {
    border-bottom: 1px solid $spn-050-color;
  }
}

.icon-wrapper {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;

  &.debited {
    color: $spr-500-color;
    background: $spr-100-color;
  }

  &.credited {
    color: $spg-500-color;
    background: $spg-100-color;
  }
}

.row-item {
  .sc-transaction {
    &-label {
      @include text(600, 12px, 16px, $spn-500-color);
    }

    &-value {
      @include text(400, 14px, 16px, $spb-900-color);
      letter-spacing: 0.4px;
    }
  }
}
