@import "~vgg-ui-react/dist/styles/partials";
@import "../../assets/styles/common/colors.scss";
@import "../../assets/styles/common/mixins.scss";

.awaiting-onboard-wrapper {
  min-height: 84vh;
  display: flex;
  flex-direction: column;

  .content-wrapper {
    width: 37%;
    min-width: 250px;
    margin: auto;

    .success-title {
      text-align: center;
      letter-spacing: 0.002em;
      @include text(600, 24px, 30px, $pure-black);
    }

    .success-content {
      @include text(400, 16px, 30px, $spn-500-color);
      text-align: center;
      margin-top: 0;
      letter-spacing: 0.002em;
    }
  }

  .card-section {
    background: $spb-050-color;
    border: 1px solid $gray-100;
    border-radius: 4px;

    .content-paragraph {
      letter-spacing: -0.048px;
      @include text(400, 16px, 24px, $dark-100);
    }

    .footnote {
      @include text(500, 14px, 16px, $spb-900-color);
      letter-spacing: 0.4px;
    }
  }

  .onboard-btn {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.4px;
  }

  .learn-more-btn {
    background: $pure-white;
    border: 1px solid $gray-100;
    @include text(500, 14px, 16px, $spn-750-color);
    letter-spacing: 0.4px;
  }
}
